<template>
  <section class="re-2025">
    <header class="hero-small">
      <div>
        <h1>Voir loin, pour concevoir la maison de demain</h1>
      </div>
    </header>
    <main>
      <img src="~@/assets/img/pages/re2025/schema.png" alt=""/>
      <h3>
        Faire construire avec Maisons Alysia, c'est choisir un constructeur qui conjugue vision, innovation et
        responsabilité. Nos engagements vont bien au-delà des normes : ils visent à garantir votre confort et votre
        sérénité, tout en préservant la planète.
      </h3>
      <div class="grid-container">
        <div class="card-content">
          <img class="logo" src="~@/assets/img/pages/re2025/re2025.jpg" alt="RE 2025"/>
          <h2>
            RE2025 : la garantie d’une maison plus respectueuse
            de l’environnement et plus performante.
          </h2>
          <div class="card-row">
            <img class="logo" src="~@/assets/img/pages/re2025/co2.jpg" alt="CO2"/>
            <div>
              <h3>Décarbonation et durabilité des bâtiments</h3>
              <p>Maisons Alysia adapte en permanence son mode constructif en privilégiant les
              matériaux faibles en carbone et les ressources renouvelables. Chaque construction
              est conçue pour minimiser son empreinte écologique, sans nuire ni à la qualité ni à la
              durabilité. Nous vous oﬀrons des maisons à la fois modernes et respectueuses de
              l'environnement, contribuant à un cadre de vie plus sain.</p>
            </div>
          </div>
          <div class="card-row">
            <img class="logo" src="~@/assets/img/pages/re2025/green.jpg" alt="picto de mesure de consommation"/>
            <div>
              <h3>Sobriété et performances énergétiques</h3>
              <p>La maîtrise de la consommation énergétique est au cœur de notre démarche. Nos
              maisons intègrent des solutions performantes : isolation de haute qualité,
              équipements économes et recours aux énergies renouvelables. Résultat ? Des factures
              énergétiques réduites et un impact environnemental considérablement diminué,
              garantissant des économies durables pour les nombreuses familles qui nous font
              confiance.</p>
            </div>
          </div>
          <div class="card-row">
            <img class="logo" src="~@/assets/img/pages/re2025/temperature.jpg" alt="picto de température"/>
            <div>
              <h3>Confort d’été, optimisation du bien-être</h3>
              <p>C’est un des points forts de la nouvelle réglementation car sensible pour les futurs
              occupants. Une maison doit être agréable en toutes saisons : avec Maisons Alysia, profitez
              d'un confort optimal même pendant les périodes de fortes chaleurs. Grâce à des techniques
              innovantes et performantes, vous bénéficiez d'un environnement intérieur sain, quelle que
              soit la météo extérieure.</p>
            </div>
          </div>
        </div>

        <div class="card-content dark">
          <h2>Chez Maisons Alysia, la RE2028 est déjà disponible.</h2>
          <div class="card-row">
            <img class="logo" src="~@/assets/img/pages/re2025/2028.png" alt="2028"/>
            <div>
              <p>
                Depuis le 1er janvier 2025, un nouveau renforcement des seuils d'émissions de carbone et
                de consommation énergétique est obligatoire.
              </p>
              <p>
                Chez Maisons Alysia, cette échéance est perçue comme une opportunité : celle d'oﬀrir des
                maisons encore plus performantes, durables et alignées sur l'objectif ultime de neutralité
                carbone d'ici 2050. C’est pourquoi nous avons déjà anticipé le prochain seuil de 2028.
              </p>
              <p>
                <router-link :to="{ name: 'constructor-network' }">Renseignez-vous auprès de votre agence Maisons Alysia</router-link>
              </p>
            </div>
          </div>
        </div>

        <card-type-img
          title="La neutralité carbone d'ici 2050">
          <template v-slot:content>
            <p>
              La réglementation RE 2020 vise trois objectifs majeurs :<br/>
            </p>
            <ul>
              <li><strong>réduire les émissions de carbone dans le secteur de la construction,</strong></li>
              <li><strong>améliorer les performances énergétiques des bâtiments,</strong></li>
              <li><strong>garantir un confort accru pour les occupants, notamment face
                aux aléas climatiques.</strong></li>
            </ul>
            <p>
              Ces objectifs s'inscrivent dans une stratégie nationale ambitieuse :
              atteindre la <strong>neutralité carbone d'ici 2050</strong>.
              Depuis 2021, la RE 2020 marque une rupture avec les pratiques
              traditionnelles de construction. Ce cadre évolutif prévoit des
              seuils de plus en plus exigeants : en 2025, en 2028 et jusqu'en
              2031 où les bâtiments neufs devront atteindre une quasi-
              neutralité carbone.
            </p>
            <p>
              Maisons Alysia se distingue par sa capacité à anticiper ces
              échéances grâce à une approche proactive et à une innovation
              constante.
            </p>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/re2025/version.jpg" alt="une maison Alysia" />
          </template>
        </card-type-img>

        <card-type-img
          title="La réglementation est exigeante, nous aussi !">
          <template v-slot:content>
            <p>
              Maisons Alysia se positionne en pionnier d'une construction
              plus durable. Chaque étape, du choix des matériaux à la
              conception des plans, est pensée pour limiter l'impact
              environnemental.
            </p>
            <p>
              En adoptant des solutions innovantes et des
              techniques éco-eﬃcaces, nous participons activement à la
              transition énergétique.
            </p>
            <p>
              <strong>Nos équipes innovent chaque jour pour proposer des
              habitats qui allient confort, performance énergétique
              et respect de l’environnement.</strong>
            </p>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/re2025/woman.jpg" alt="une maison Alysia" />
          </template>
        </card-type-img>

        <div class="actions">
          <router-link :to="{ name: 'home' }">
            <app-button theme="primary">Configurer mon projet maintenant →</app-button>
          </router-link>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  components: {
    CardTypeImg,
  },
  data() {
    return {
    };
  },
};

</script>

<style lang="sass">
.re-2025
  background-color: $medium-bg
  .hero-small
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/hero-RE-2025.png)
  main
    padding: 50px 0
    text-align: center
    background-color: $medium-bg
    > img
      margin: 20px auto
      width: calc(100% - 40px)
      max-width: 1000px
    h3
      width: 80%
      margin: 3rem auto
    a
      text-decoration: none
      font-weight: 700
      color: $primary
    .card-content
      @include card-content
      margin: 40px auto
      padding: 80px 70px
      &.dark
        background: $black
        h2, p
          color: white
      > img
        margin: 0 auto 50px
        max-width: 260px
      > h2
        margin-bottom: 50px
        font-size: 2.6rem
        line-height: 140%
      .card-row
        display: flex
        justify-content: center
        align-items: center
        gap: 60px
        margin: 40px 0
        > img
          max-width: 300px
        div
          text-align: left
          h3
            margin-bottom: 1rem
            font-size: 1.65rem
          p ~ p
            margin-top: 20px
    .card-type-img
      margin: 20px auto 0
      text-align: left
      p ~ p
        margin-top: 20px
    .actions
      display: flex
      justify-content: center
      align-items: center
      padding: 60px 0 20px

  @media (max-width: 768px)
    main
      opacity: 1
      .card-content
        .card-row
          flex-direction: column
</style>
